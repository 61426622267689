import React, { useEffect, useState} from 'react';
import firebase from '../firebase'
import './Css/sidebar.css'
import { MDBPopover, MDBPopoverBody, MDBPopoverHeader, MDBBtn, MDBInput} from "mdbreact";
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import MenuIcon from '@material-ui/icons/Menu';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import {useHistory} from 'react-router-dom'
const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

const  Sidebar = ()=> {

   const classes = useStyles();
const [channel ,setChannel] =useState([])
const [input ,setInput] =useState('')
const history  = useHistory()
const [open, setOpen] = React.useState(true);


useEffect(()=>{    
firebase.firestore().collection('rooms').onSnapshot(snapshot=>(
setChannel( snapshot.docs.map(doc=>({
id:doc.id,
name:doc.data().name
})))
))
},[])
const addChannel = ()=>{
// const channelName =  prompt('Inter channel name');
if(input){
firebase.firestore().collection('rooms').add({
name:input
})
} 
setInput('')       
}

 const selectChannel = (id,title)=>{
   if(id){
       history.push(`/rooms/${id}`)
    }else {
        history.push(title)
    }
}


  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
//  console.log(input)
return (
   <div className={classes.root} style={{float:'left'}}>
     <CssBaseline />
     <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
       <Toolbar className={classes.toolbar}>
         <IconButton
           edge="start"
           color="inherit"
           aria-label="open drawer"
           onClick={handleDrawerOpen}
           className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
         >
           <MenuIcon />
         </IconButton>
         <Typography component="h1" variant="h6" color="red" noWrap className={classes.title}>
           Dashboard
         </Typography>
         <IconButton color="inherit">
           <Badge badgeContent={4} color="secondary">
             <NotificationsIcon />
           </Badge>
         </IconButton>
       </Toolbar>
     </AppBar>
     <Drawer
       variant="permanent"
       classes={{
         paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
       }}
       open={open}
     >
       <div className={classes.toolbarIcon}>
         <IconButton onClick={handleDrawerClose}>
           <ChevronLeftIcon />
         </IconButton>
       </div>
       <MDBPopover
     placement="bottom"
     popover
     clickable
     id="popper3"
     >
     <MDBBtn >Add Channel</MDBBtn>
     <div className="p-5">
        <MDBPopoverHeader>Add new channel</MDBPopoverHeader>
        <MDBPopoverBody>
           <MDBInput label="Enter channel name" value={input} onChange={(e)=>
           setInput(e.target.value)} 
           group type="text" validate error="wrong"
           success="right" />
           <div className="text-center">
              <MDBBtn color="primary" onClick={()=>addChannel()} >Create</MDBBtn>
           </div>
        </MDBPopoverBody>
     </div>
  </MDBPopover>
    
       <Divider />
      {
        channel.map((doc , key)=>{
          return( <ListItem button key={key}>
           <ListItemIcon>
             <DashboardIcon />
           </ListItemIcon>
           <ListItemText onClick={()=>selectChannel(doc.id,doc.name)} primary={doc.name}/>
          </ListItem>)
        })
      }
       <Divider />
     </Drawer>
     
   </div>
 );
}
export default Sidebar;